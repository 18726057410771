.home {
  flex: 1; }
  .home h2 {
    margin: 100px 0; }

.home .card {
  align-content: center;
  align-items: center;
  margin: 10px; }

.home .card-body {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column; }

.home .row {
  margin: 15px;
  justify-content: center; }

.home.container-fluid {
  max-width: 100vw;
  padding: 0;
  margin-top: -25px; }
  .home.container-fluid h1 {
    text-align: center; }

.home.container-fluid .splashRow {
  padding: 0;
  margin: 0; }
  .home.container-fluid .splashRow .col {
    padding: 0; }
  .home.container-fluid .splashRow .splashImage {
    display: block;
    width: 100vw;
    height: 475px;
    object-fit: cover; }
