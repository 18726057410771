body {
  margin: 0;
  padding: 0;
  background: #fdfdfd;
  font-family: 'Karla', sans-serif;
  color: #323232;
  font-size: 16px; }

.app-root,
.swal-text,
.swal-button {
  font-family: 'Karla', sans-serif; }

.swal-button {
  background-color: #f26923; }

h1,
h2,
.swal-title {
  font-family: 'Karla', sans-serif;
  color: #01aab1;
  margin: 10px 0; }

h3,
h4,
h5,
h6 {
  font-family: 'Karla', sans-serif;
  color: #01aab1;
  margin: 5px 0; }

a {
  color: #f26923;
  text-decoration: underline; }

.nav-link,
nav a,
.btn {
  text-decoration: none; }

#root {
  min-height: 100vh; }

form {
  font-family: 'Karla', sans-serif; }

.app-container {
  min-height: 100vh; }

.app-container > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh; }

.container-fluid {
  max-width: 80vw; }

.btn,
.sq-button-wrapper > button {
  background-color: #f26923;
  color: #ffffff !important;
  margin: 5px;
  background-image: none !important;
  font-family: 'Karla', sans-serif; }

button:hover,
.btn:hover,
.sq-button-wrapper > button:hover {
  color: #323232;
  cursor: pointer; }

.cancel.btn {
  background-color: #ed2124; }

.confirm.btn,
.sq-button-wrapper > button {
  background-color: #18ac4b; }

.card {
  max-width: 350px;
  min-width: 350px;
  margin-bottom: 10px !important; }

.center-form {
  max-width: 100%;
  margin: 0 auto; }

form > div button[type='submit']:before,
form > div > div > span:before {
  display: none !important;
  font: inherit !important; }

form > div > div {
  flex: 2 2;
  max-width: 100%; }

form > div > label {
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: row-reverse; }

.rw-widget,
.rw-widget * {
  padding: 0; }

.terms-conditions {
  justify-content: flex-end; }

form ul li:first-child {
  text-align: left; }
