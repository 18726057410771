.app-container .loadingOverlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7); }
  .app-container .loadingOverlay .spinner {
    animation: rotator 1.4s linear infinite; }

@keyframes rotator {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(270deg); } }
  .app-container .loadingOverlay .path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite; }

@keyframes colors {
  0% {
    stroke: #ED2124; }
  33% {
    stroke: #F26923; }
  66% {
    stroke: #18AC4B; }
  100% {
    stroke: #01AAB1; } }

@keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg); } }

.loadingOverlay.hide {
  display: none; }
