body {
  margin: 0;
  padding: 0;
  background: #fdfdfd;
  font-family: 'Karla', sans-serif;
  color: #323232;
  font-size: 16px; }

.app-root,
.swal-text,
.swal-button {
  font-family: 'Karla', sans-serif; }

.swal-button {
  background-color: #f26923; }

h1,
h2,
.swal-title {
  font-family: 'Karla', sans-serif;
  color: #01aab1;
  margin: 10px 0; }

h3,
h4,
h5,
h6 {
  font-family: 'Karla', sans-serif;
  color: #01aab1;
  margin: 5px 0; }

a {
  color: #f26923;
  text-decoration: underline; }

.nav-link,
nav a,
.btn {
  text-decoration: none; }

#root {
  min-height: 100vh; }

form {
  font-family: 'Karla', sans-serif; }

.app-container {
  min-height: 100vh; }

.app-container > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh; }

.container-fluid {
  max-width: 80vw; }

.btn,
.sq-button-wrapper > button {
  background-color: #f26923;
  color: #ffffff !important;
  margin: 5px;
  background-image: none !important;
  font-family: 'Karla', sans-serif; }

button:hover,
.btn:hover,
.sq-button-wrapper > button:hover {
  color: #323232;
  cursor: pointer; }

.cancel.btn {
  background-color: #ed2124; }

.confirm.btn,
.sq-button-wrapper > button {
  background-color: #18ac4b; }

.card {
  max-width: 350px;
  min-width: 350px;
  margin-bottom: 10px !important; }

.center-form {
  max-width: 100%;
  margin: 0 auto; }

form > div button[type='submit']:before,
form > div > div > span:before {
  display: none !important;
  font: inherit !important; }

form > div > div {
  flex: 2 2;
  max-width: 100%; }

form > div > label {
  min-width: 0;
  flex: 1 1;
  display: flex;
  flex-direction: row-reverse; }

.rw-widget,
.rw-widget * {
  padding: 0; }

.terms-conditions {
  justify-content: flex-end; }

form ul li:first-child {
  text-align: left; }


.app-container .loadingOverlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7); }
  .app-container .loadingOverlay .spinner {
    -webkit-animation: rotator 1.4s linear infinite;
            animation: rotator 1.4s linear infinite; }

@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }

@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }
  .app-container .loadingOverlay .path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
            animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite; }

@-webkit-keyframes colors {
  0% {
    stroke: #ED2124; }
  33% {
    stroke: #F26923; }
  66% {
    stroke: #18AC4B; }
  100% {
    stroke: #01AAB1; } }

@keyframes colors {
  0% {
    stroke: #ED2124; }
  33% {
    stroke: #F26923; }
  66% {
    stroke: #18AC4B; }
  100% {
    stroke: #01AAB1; } }

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }

@keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }

.loadingOverlay.hide {
  display: none; }

.footer {
  background-color: #f26923;
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  min-height: 100px; }
  .footer a {
    color: #ffffff;
    text-decoration: none;
    margin: 0 50px;
    font-size: 1rem; }
  .footer a:hover {
    color: #01aab1; }

.navbar {
  background-color: #01aab1 !important;
  min-height: 150px;
  margin-bottom: 25px; }

.collapse.navbar-collapse {
  margin-right: 100px !important; }

.nav-item a {
  font-family: 'Karla', sans-serif;
  text-transform: uppercase; }

form > div > label {
  min-width: 200px; }

.profile-header,
.profile-subheader {
  text-align: center; }

#messageModalText {
  min-height: 200px;
  min-width: 400px; }



.request-container {
  margin-bottom: 25px; }

form > div > label {
  min-width: 200px; }

form > div > label {
  min-width: 200px; }

.termsSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center; }

.home {
  flex: 1 1; }
  .home h2 {
    margin: 100px 0; }

.home .card {
  align-content: center;
  align-items: center;
  margin: 10px; }

.home .card-body {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column; }

.home .row {
  margin: 15px;
  justify-content: center; }

.home.container-fluid {
  max-width: 100vw;
  padding: 0;
  margin-top: -25px; }
  .home.container-fluid h1 {
    text-align: center; }

.home.container-fluid .splashRow {
  padding: 0;
  margin: 0; }
  .home.container-fluid .splashRow .col {
    padding: 0; }
  .home.container-fluid .splashRow .splashImage {
    display: block;
    width: 100vw;
    height: 475px;
    object-fit: cover; }

#messageModalText {
  min-height: 200px;
  min-width: 400px; }




.balance {
  font-size: 20px;
}

.form-input {
  margin: 10px 0;
}

.form-input input {
  display: block;
  width: 100%;
}

.form-input textarea {
  display: block;
  width: 100%;
  min-height: 200px;
}

.paymentRow {
  margin-bottom: 25px;
}

/* Define how SqPaymentForm iframes should look */

.sq-input {
  border: 1px solid #dfdfdf;
  outline-offset: -2px;
  margin-bottom: 5px;
  display: inline-block;
}

/* Define how SqPaymentForm iframes should look when they have focus */

.sq-input--focus {
  outline: 5px auto #3b99fc;
}

/* Define how SqPaymentForm iframes should look when they contain invalid values */

.sq-input--error {
  outline: 5px auto #ff6161;
}

/* Customize the "Pay with Credit Card" button */

.button-credit-card {
  min-width: 200px;
  min-height: 20px;
  padding: 0;
  margin: 5px;
  line-height: 20px;
  box-shadow: 2px 2px 1px #c8c8c8;
  background: white;
  border-radius: 5px;
  border: 1px solid #c8c8c8;
  font-weight: bold;
  cursor: pointer;
}

/* Customize the "{{Wallet}} not enabled" message */

.wallet-not-enabled {
  min-width: 200px;
  min-height: 40px;
  max-height: 64px;
  padding: 0;
  margin: 10px;
  line-height: 40px;
  background: #eee;
  border-radius: 5px;
  font-weight: lighter;
  font-style: italic;
  font-family: inherit;
  display: block;
}

/* Customize the Apple Pay on the Web button */

.button-apple-pay {
  min-width: 200px;
  min-height: 40px;
  max-height: 64px;
  padding: 0;
  margin: 10px;
  background-image: -webkit-named-image(apple-pay-logo-white);
  background-color: black;
  background-size: 100% 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 5px;
  cursor: pointer;
  display: none;
}

/* Customize the Masterpass button */

.button-masterpass {
  min-width: 200px;
  min-height: 40px;
  max-height: 40px;
  padding: 0;
  margin: 10px;
  background-image: url(https://static.masterpass.com/dyn/img/btn/global/mp_chk_btn_147x034px.svg);
  background-color: black;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 5px;
  border-color: white;
  cursor: pointer;
  display: none;
}

#sq-walletbox {
  float: left;
  margin: 5px;
  padding: 10px;
  text-align: center;
  vertical-align: top;
  font-weight: bold;
}

#sq-ccbox {
  float: left;
  margin: 5px;
  padding: 10px;
  text-align: center;
  vertical-align: top;
  font-weight: bold;
}

form > div > label {
  min-width: 200px; }

.termsSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center; }

form > div > label {
  min-width: 200px; }

form > div > label {
  min-width: 200px; }

.profile-header,
.profile-subheader {
  text-align: center; }

