.navbar {
  background-color: #01aab1 !important;
  min-height: 150px;
  margin-bottom: 25px; }

.collapse.navbar-collapse {
  margin-right: 100px !important; }

.nav-item a {
  font-family: 'Karla', sans-serif;
  text-transform: uppercase; }
