.footer {
  background-color: #f26923;
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  min-height: 100px; }
  .footer a {
    color: #ffffff;
    text-decoration: none;
    margin: 0 50px;
    font-size: 1rem; }
  .footer a:hover {
    color: #01aab1; }
